import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";

const ProjectsPage = () => {
  return (
    <Layout>
      <h1 className="mobile-header lg:desktop-header mb-4 lg:mb-4">Projects</h1>
      <div className="flex flex-wrap">
        <div>
          <h2 className="font-barriecito text-3xl pb-4">The Venus Projects</h2>

          <p className="lg:max-w-md mobile-body lg:desktop-body">
            The Birth of the Venus Projects came of listening to my Latin
            girlfriend’s chat together with wide open mouths and voices, while
            within the safety of the sisterhood, but when they stepped before a
            crowd or into their own homes, their throats tightened, and they
            became a mere whisper.
            <br />
            Being an American woman raised in the 60’s, trained in Theatre and
            possessing the MOUTH THAT ROARED, I took it upon myself to help my
            friends open their voices, learn to breathe deeply and feel the
            freedom of expression.
            <br />
            <br />
            We met twice per week, to explore our inner terrain, inner
            personality training that had created this disconnect from throat to
            sound. We wrote, we moved, we sang, we danced, and four months later
            we put on a show of 9 monologues, interweaving our-selves into each
            other’s pieces and asking others to step in to perform in ours.
            <br /> <br />
            We have birthed three projects, thus far, and when we can gather, in
            the midst of careers, child rearing and general living, we shall
            create another.
            <br />
            In the Meantime, I am available to come to your town and put
            together a workshop format to address similar vocal issues and
            explore our creativity and inner messages that need to be birthed!!!
            <br /> <br /> Contact me at{" "}
            <strong> Dana@danakopfermaxey.com </strong> or
            <strong> madameflore@yahoo.co.uk</strong>
          </p>
        </div>
        <StaticImage
          width={641}
          src="../images/projects/venus.jpg"
          alt="flyer"
          placeholder="tracedSVG"
          className="mx-auto mt-4 lg:mt-0"
        />
      </div>
      <h2 className="font-barriecito text-3xl mt-8">Dana’s Writting</h2>
      <br />

      <p className="mb-2">
        <strong>Screen Plays</strong>
        <br />
        Lost and Found -{" "}
        <strong>
          Full length feature based upon a true 1980’s relationship between a
          gang and a young architect.
        </strong>
        <br />
        <br />
        <br />
        <strong>Plays</strong>
        <br />
        Chrysallis -
        <strong>
          {" "}
          A full length play about the creative process and our internal
          monologues.
        </strong>
        <br />
        <br />
        <br />
        <strong>Songwriting</strong>
        <br />
        Rodeo Queen- Country Americana.
        <br />
        <br />
        <br />
        Interested parties can contact Dana at
        <strong> dana@danakopfermaxey.com.</strong>
      </p>
    </Layout>
  );
};

export default ProjectsPage;
